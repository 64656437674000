import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../store"
import { setStep } from "../../store/slices/fishingSlice"
import "./style.scss"

const ToMainPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate("/online-buy-fishing/")
    dispatch(setStep("01"))
  }

  return (
    <div className="to-main" onClick={handleBack}>
      <svg
        className="back__svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 5L8.5 12L15.5 19"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <span>На главную страницу</span>
    </div>
  )
}

export default ToMainPage
